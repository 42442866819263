
export default {
    name: 'RegisterPage3',
    data: () => ({
        modal: false,
    }),
    mounted() {
        this.modal = true
    },
}
