import { render, staticRenderFns } from "./RegisterPage.vue?vue&type=template&id=2d3aa3ff"
import script from "./RegisterPage.vue?vue&type=script&lang=js"
export * from "./RegisterPage.vue?vue&type=script&lang=js"
import style0 from "./RegisterPage.vue?vue&type=style&index=0&id=2d3aa3ff&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme3PhoneNumber: require('/var/app/components/Theme3/PhoneNumber.vue').default,Theme3ImageBackground: require('/var/app/components/Theme3/ImageBackground.vue').default})
