export default {
	head() {
		return {
			title: `Join us - ${this.$config.DOMAIN?.includes('www')
				? this.$config.DOMAIN?.split('.')[1]
				: this.$config.DOMAIN?.split('.')[0]
				}`,
			meta: [
				{
					hid: 'og:title',
					name: 'og:title',
					content: `Join us - ${this.$config.DOMAIN?.includes('www')
						? this.$config.DOMAIN?.split('.')[1]
						: this.$config.DOMAIN?.split('.')[0]
						}`, // This can be overridden by page-level meta tags.
				},
			],
		}
	},
}