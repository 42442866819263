
export default {
    name: 'RegisterPage',
    data: () => ({
        modal: false,
    }),
    mounted() {
        this.modal = true
    },
}
