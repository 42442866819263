import { render, staticRenderFns } from "./RegisterPage.vue?vue&type=template&id=135470f4"
import script from "./RegisterPage.vue?vue&type=script&lang=js"
export * from "./RegisterPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme3DesktopRegisterModal: require('/var/app/components/Theme3/Desktop/RegisterModal.vue').default})
